// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.breadcrumb {
  margin-bottom: 10px;
}

.main .container-fluid {
  padding: 0px 20px;
}

/*Override theme*/
.sidebar {
  background: #c4161c;

  .nav-link.active {
    background: #7b0f13;
  }
}

.sidebar {
  .nav-link {
    .nav-icon {
      color: white;
    }
  }

  .nav-link:hover {
    background: #ff343b;
  }
}
